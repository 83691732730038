import React, { useEffect, useState } from 'react';
import Footer from '../../components/footer';
import Header from "../../components/header";
import Layout from "../../components/shared-layout";
import FilterElement from "../../components/filter-element";
import useStores from "../../hooks/use-stores";
import { observer } from 'mobx-react';
import logo from '../../images/logo.png';
import EditIcon from '@material-ui/icons/Edit';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import { Button, CircularProgress, MenuItem, Select, TextField } from '@material-ui/core';
import SaveIcon from '@material-ui/icons/Save';
import EditSchoolLayout from '../../components/edit-school-layout';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import AddIcon from '@material-ui/icons/Add';
import CustomisedModal from '../../components/customised-modal';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import { MODAL_WINDOW } from '../../constants/style';
import LoadingPanel from '../../components/loading-panel';

const SchoolsPage = observer(() => {
    const { collegeStore } = useStores();
    const [currentCollegeId, setCurrentCollegeId] = useState(0);
    const [currentCollege, setCurrentCollege] = useState([]);
    const [currentCollegeName, setCurrentCollegeName] = useState("");
    const [emailServer, setEmailServer] = useState("");
    const [emailAccount, setEmailAccount] = useState("");
    const [emailPwd, setEmailPwd] = useState("");
    const [confirmPwd, setConfirmPwd] = useState("");
    const [showEdit, setShowEdit] = useState("");
    const [pageSize, setPageSize] = useState(2);
    const [skip, setSkip] = useState(0);
    const [showModal, setShowModal] = useState(false);

    useEffect(() => {
        collegeStore.getCollegeList(pageSize, skip);
    }, [skip]);


    const onChangePages = (direction: string) => {
        let tempSkip;
        if (direction == "forward") {
            if (skip + pageSize >= collegeStore.collegeList.total) return;
            tempSkip = skip + pageSize;
        } else {
            if (skip == 0) return;
            tempSkip = skip - pageSize;
        }
        setSkip(tempSkip);
    }

    const onDisplayCollegeDetail = (id) => {
        setCurrentCollegeId(id);
        let college = collegeStore.collegeList.data.filter(c => c.id == id);

        setCurrentCollegeName(college[0].name);
        setCurrentCollege(college);
        setShowEdit("update");
        // Check this is needed in the future
        // window.scroll({
        //     top: document.body.offsetHeight,
        //     left: 0,
        //     behavior: 'smooth'
        // });
    }

    const createCollege = () => {
        if (confirmPwd != emailPwd) {
            return;
        }
        collegeStore.createCollege({
            name: currentCollegeName,
            styleSetting: "Primary",
            emailServer,
            emailAccount,
            emailPassword: emailPwd,
            isActive: 1,
        }).then(() => {
            collegeStore.getCollegeList(pageSize, 0);
            setSkip(0);
            setShowEdit("");
            setCurrentCollegeName("");
            setEmailAccount("");
            setEmailPwd("");
            setEmailServer("");
            setConfirmPwd("");
        });
    }

    const updateCollegeDetail = () => {
        collegeStore.updateCollegeInfo({
            id: currentCollegeId,
            name: currentCollegeName,
            styleSetting: "",
            emailAccount: "",
            emailServer: "",
            emailPassword: "",
        }).then(() => {
            collegeStore.getCollegeList(pageSize, skip);
        });
        onCompleteEdit();
    }

    const onDisplayAddCollege = () => {
        onCompleteEdit();
        setShowEdit("create");
        // Check this is needed in the future
        // window.scroll({
        //     top: document.body.offsetHeight,
        //     left: 0,
        //     behavior: 'smooth'
        // });
    }

    const displayDeleteModal = (id) => {
        setCurrentCollegeId(id);
        setShowModal(true);
    }

    const onDeleteCollege = () => {
        collegeStore.updateCollegeStatus(currentCollegeId)
            .then(() => {
                collegeStore.getCollegeList(pageSize, 0);
                setSkip(0);
            });
        setShowModal(false);
    }

    const onCompleteEdit = () => {
        setCurrentCollegeId(0);
        setCurrentCollege([]);
        setCurrentCollegeName("");
        setShowEdit("");
    }

    return (
        <>
            {
                typeof window !== "undefined" &&
                <Layout
                    showStaffList={false}
                    showSeachStudent={false}
                    showFilter={false}
                    showDomain={false}
                    showClassCode={false}
                    showYearSemTerm={false}
                    showYearRange={false}
                    showNewsArea={true}
                >
                    <title>Schools</title>
                    {collegeStore.loading && <LoadingPanel />}
                    <div className="my-2 mx-2 col-span-4 flex flex-col">
                        <div className="bg-barPrimary pl-4 py-2 text-white">
                            School List
                        </div>
                        <div className="bg-white px-20">
                            <div className="w-full text-barPrimary text-xl text-center font-bold my-8">
                                School List
                            </div>
                            <div className="w-full text-right mb-4">
                                <Button
                                    className="bg-buttonPrimary text-white p-2"
                                    component="span"
                                    onClick={onDisplayAddCollege}
                                >
                                    <AddIcon />
                                    <span className="ml-2">Add New School</span>
                                </Button>
                            </div>

                            {
                                collegeStore.loadingList ? <div className="w-full text-center p-20"><CircularProgress className="text-barPrimary" /></div>
                                    :
                                    <table className="w-full text-center">
                                        <tr className="bg-barPrimary">
                                            <th className="text-white p-4 text-center">Logo</th>
                                            <th className="text-white p-4 text-center">School Name</th>
                                            <th className="text-white p-4 text-center">Actions</th>
                                        </tr>
                                        {
                                            collegeStore.collegeList.data.map((college) => (
                                                <tr>
                                                    <td className="border" style={{ width: 300, height: 150 }}>
                                                        <img
                                                            src={logo}
                                                            alt="logo"
                                                            className="object-contain w-full h-full"
                                                            style={{ margin: "0 auto" }}
                                                        />
                                                    </td>
                                                    <td className="border text-center text-xl">{college.name}</td>
                                                    <td className="border text-center p-4">
                                                        <Button
                                                            className="bg-buttonPrimary text-white mx-2 p-2"
                                                            component="span"
                                                            onClick={() => onDisplayCollegeDetail(college.id)}
                                                        >
                                                            <EditIcon />
                                                            <span className="ml-2">Edit</span>
                                                        </Button>

                                                        <Button
                                                            className="text-white mx-2 p-2"
                                                            style={{ background: 'red' }}
                                                            component="span"
                                                            onClick={() => displayDeleteModal(college.id)}
                                                        >
                                                            <DeleteForeverIcon />
                                                            <span className="ml-2">Delete</span>
                                                        </Button>
                                                    </td>
                                                </tr>
                                            ))
                                        }
                                    </table>
                            }
                            {
                                !collegeStore.loading &&
                                <div className="w-full text-right my-8 flex justify-end">
                                    <div className="mr-8 mt-2">
                                        {skip + pageSize > collegeStore.collegeList.total ? collegeStore.collegeList.total : skip + pageSize} of {collegeStore.collegeList.total}
                                    </div>
                                    <Button
                                        className="bg-buttonPrimary text-white mr-4"
                                        component="span"
                                        onClick={() => onChangePages("backward")}
                                    >
                                        <ArrowBackIosIcon />
                                    </Button>
                                    <Button
                                        className="bg-buttonPrimary text-white"
                                        component="span"
                                        onClick={() => onChangePages("forward")}
                                    >
                                        <ArrowForwardIosIcon />
                                    </Button>
                                </div>

                            }
                            {
                                showModal &&
                                <CustomisedModal showModal={showModal}>
                                    <div className={`${MODAL_WINDOW} p-10`} style={{ transform: 'translate(-50%, -50%)' }}>
                                        <div className="text-xl mb-8">
                                            <ErrorOutlineIcon className="text-3xl mr-4 mb" />
                                            <span className="pt-10">Do you wish to delete this school?</span>
                                        </div>
                                        <div className="w-full text-right">
                                            <Button
                                                className="bg-buttonPrimary text-white mx-2 p-2"
                                                component="span"
                                                onClick={() => setShowModal(false)}
                                            >
                                                Cancel
                                            </Button>

                                            <Button
                                                className="text-white mx-2 p-2"
                                                style={{ background: 'red' }}
                                                component="span"
                                                onClick={onDeleteCollege}
                                            >
                                                Delete Now
                                            </Button>
                                        </div>
                                    </div>
                                </CustomisedModal>
                            }

                        </div>

                        {
                            showEdit != "" &&
                            <div>
                                <div className="bg-barPrimary pl-4 py-2 text-white">
                                    School Information
                                </div>
                                <div className="bg-white">
                                    <div className="w-full text-barPrimary text-xl text-center font-bold p-8">
                                        {showEdit == "create" ? "Add School Information" : "School Information Management"}
                                    </div>
                                    <EditSchoolLayout
                                        logo={logo}
                                        collegeName={currentCollegeName}
                                        onChangeCollegeName={(value) => setCurrentCollegeName(value.target.value)}
                                        buttonRequired={showEdit == "update" ? true : false}
                                        callback={updateCollegeDetail}
                                    />
                                    {
                                        showEdit == "create" &&
                                        <div>
                                            <div className="bg-barPrimary pl-4 py-2 mt-4 text-white">
                                                Email Server Settings
                                            </div>
                                            <div className="p-10 pl-40">
                                                <div className="mb-4">
                                                    <div className="text-buttonPrimary font-bold mt-2">
                                                        Email Service
                                                    </div>
                                                    <div className="w-1/2">
                                                        <input
                                                            className="p-2 rounded-md border-buttonPrimary border-2 w-full"
                                                            value={emailServer}
                                                            onChange={(value) => setEmailServer(value.target.value)}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="mb-4">
                                                    <div className="text-buttonPrimary font-bold mt-2">
                                                        Email Account
                                                    </div>
                                                    <div className="w-1/2">
                                                        <input
                                                            className="p-2 rounded-md border-buttonPrimary border-2 w-full"
                                                            value={emailAccount}
                                                            onChange={(value) => setEmailAccount(value.target.value)}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="">
                                                    <div className="text-buttonPrimary font-bold mt-2">
                                                        Email Password
                                                    </div>
                                                    <div className="w-1/2">
                                                        <input
                                                            type="password"
                                                            className="p-2 rounded-md border-buttonPrimary border-2 w-full"
                                                            value={emailPwd}
                                                            onChange={(value) => setEmailPwd(value.target.value)}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="">
                                                    <div className="text-buttonPrimary font-bold mt-2">
                                                        Confirm Password
                                                    </div>
                                                    <div className="w-1/2">
                                                        <input
                                                            type="password"
                                                            className="p-2 rounded-md border-buttonPrimary border-2 w-full"
                                                            value={confirmPwd}
                                                            onChange={(value) => setConfirmPwd(value.target.value)}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="w-full text-right px-20 pt-0">
                                                {
                                                    collegeStore.loading ? <div className="w-full text-center p-20"><CircularProgress className="text-barPrimary" /></div>
                                                        :
                                                        <div className="w-full text-right px-20 pt-0">
                                                            <Button
                                                                className="px-4 py-2 bg-buttonPrimary text-white text-base mb-4"
                                                                component="span"
                                                                onClick={createCollege}
                                                            >
                                                                <SaveIcon /> <span className="ml-2 mt">Save College Detail</span>
                                                            </Button>
                                                        </div>
                                                }

                                            </div>
                                        </div>
                                    }

                                </div>
                            </div>
                        }
                    </div>

                </Layout>
            }
        </>
    )
})

export default SchoolsPage;
