import React, { useState } from "react";
import { Button, Select } from "@material-ui/core";
import { observer } from "mobx-react";
import PublishIcon from '@material-ui/icons/Publish';

const EditSchoolLayout = observer(({
  collegeName, onChangeCollegeName, logo, buttonRequired, callback
}) => {

  return (
    <div>
      <div className="px-20 w-full">
        <div className="mx-8">
          <div className="text-buttonPrimary font-bold mb-4">
            School Name
          </div>
          <div className="">
            <input
              className="p-2 rounded-md border-buttonPrimary border-2 w-full"
              value={collegeName}
              onChange={onChangeCollegeName}
            />
          </div>
          <div className="w-full text-right">
            <input
              className="hidden"
              id="upload-logo-button"
              type="file"
              accept="image/png, image/jpeg"
            />
            <label htmlFor="upload-logo-button">
              <Button
                className="bg-buttonPrimary text-white mt-4 p-2"
                component="span"
              >
                <PublishIcon /><span className="ml">Upload</span>
              </Button>
            </label>
          </div>

        </div>
      </div>

      <div className="col-span-4 lg:col-span-2">
        <div className="h-60 w-64" style={{ margin: '0 auto' }}>
          <img
            className="w-full h-full object-cover border p-4"
            src={logo}
            alt="logo"
          />
        </div>
      </div>

      {
        buttonRequired &&
        <div className="px-20 text-right py-8 w-full">
          <Button
            className="bg-buttonPrimary text-white"
            component="span"
            onClick={callback}
          >
            Save
          </Button>
        </div>
      }

    </div>
  )
})

export default EditSchoolLayout;